<template>
  <div class="d-flex align-center escalation-processor-run-audit">
    <span class="mr-2">{{$t('t.EscalationProtocolsAudit')}}</span>
    <picker
      class="accounts"
      :attach="false"
      dense
      label=""
      :placeholder="$t('t.AccountToAudit')"
      document-types="accounts"
      :selected-id.sync="computedAccountId"
      :clearable="false"
      :audit="audit"
    />
    <div
      class="d-flex align-center ml-2"
      v-if="blockedByFollowUpWorkItems != null"
    >
      <audit-bool-icon :value="!blockedByFollowUpWorkItems" />
      <span class="ml-2">
        {{$t('t.NoBlockingFollowUp')}}
      </span>
    </div>
  </div>
</template>

<script>

import auditController from '@/auditController'

export default {
  components: {
    Picker: () => import('@/components/picker'),
    AuditBoolIcon: () => import('@/components/audit-bool-icon')

  },
  computed: {
    computedAccountId: {
      get () {
        return this.auditController.dataAudit.view.id
      },
      set (v) {
        if (!v) {
          return
        }

        auditController.audit(this.auditController.dataAudit.source, { type: 'accounts', id: v })
      }
    },
    audit () {
      return { source: auditController.dataAudit.source }
    },
    computedAudit: {
      get () {
        return this.auditController.dataAudit
      },
      set (v) {
        this.auditController.dataAudit = v
        this.getAudit()
      }
    },
    blockedByFollowUpWorkItems () {
      return this.dataAuditResult?.annotations?.blockedByFollowUpWorkItems
    },
    hasFollowUpWorkItems () {
      return this.dataAuditResult?.annotations?.hasFollowUpWorkItems
    },
    allowFollowUpToBlock () {
      return this.dataAuditResult?.annotations?.allowFollowUpToBlock
    }
  },
  data () {
    return {
      auditController: auditController,
      dataAuditResult: null
    }
  },
  methods: {
    async getAudit () {
      this.dataAuditResult = null
      const apiUrl = '/core/v6/audit'
      const result = await this.$http().post(`${apiUrl}`, this.computedAudit)
      this.dataAuditResult = result.data
    }
  },
  watch: {
    'auditController.dataAudit': {
      immediate: true,
      handler (n, o) {
        if (n || o) {
          this.getAudit()
        }
      }
    }
  },
  props: {
  }
}
</script>

<style lang="stylus" scoped>
.accounts
  min-width 300px
</style>
